/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

// minWidth
.minWidth10 {
    min-width: 10px !important;
}
.minWidth20 {
    min-width: 20px !important;
}
.minWidth30 {
    min-width: 30px !important;
}
/* Add more classes as needed */
.minWidth40 {
    min-width: 40px !important;
}
.minWidth50 {
    min-width: 50px !important;
}
.minWidth60 {
    min-width: 60px !important;
}
.minWidth70 {
    min-width: 70px !important;
}
.minWidth80 {
    min-width: 80px !important;
}
.minWidth90 {
    min-width: 90px !important;
}
.minWidth100 {
    min-width: 100px !important;
}
.minWidth110 {
    min-width: 110px !important;
}
.minWidth120 {
    min-width: 120px !important;
}
.minWidth130 {
    min-width: 130px !important;
}
.minWidth140 {
    min-width: 140px !important;
}
.minWidth150 {
    min-width: 150px !important;
}
.minWidth160 {
    min-width: 160px !important;
}
.minWidth170 {
    min-width: 170px !important;
}
.minWidth180 {
    min-width: 180px !important;
}
.minWidth190 {
    min-width: 190px !important;
}
.minWidth200 {
    min-width: 200px !important;
}

// maxWidth
.minWidth10 {
    min-width: 10px !important;
}
.minWidth20 {
    min-width: 20px !important;
}
.minWidth30 {
    min-width: 30px !important;
}
/* Add more classes as needed */
.minWidth40 {
    min-width: 40px !important;
}
.minWidth50 {
    min-width: 50px !important;
}
.minWidth60 {
    min-width: 60px !important;
}
.minWidth70 {
    min-width: 70px !important;
}
.minWidth80 {
    min-width: 80px !important;
}
.minWidth90 {
    min-width: 90px !important;
}
.minWidth100 {
    min-width: 100px !important;
}
.minWidth110 {
    min-width: 110px !important;
}
.minWidth120 {
    min-width: 120px !important;
}
.minWidth130 {
    min-width: 130px !important;
}
.minWidth140 {
    min-width: 140px !important;
}
.minWidth150 {
    min-width: 150px !important;
}
.minWidth160 {
    min-width: 160px !important;
}
.minWidth170 {
    min-width: 170px !important;
}
.minWidth180 {
    min-width: 180px !important;
}
.minWidth190 {
    min-width: 190px !important;
}
.minWidth200 {
    min-width: 200px !important;
}
